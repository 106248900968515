import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Required reading`}</h2>
    <p>{`Before using these utilities, `}<strong parentName="p">{`you should be familiar with CSS3 Flexible Box spec`}</strong>{`. If you are not, check out MDN's guide:`}</p>
    <p>{`📖 `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Using_CSS_flexible_boxes"
        }}>{`Using CSS Flexible Boxes`}</a></strong></p>
    <h2>{`Flex container`}</h2>
    <p>{`Use these classes to make an element lay out its content using the flexbox model. Each `}<strong parentName="p">{`direct`}</strong>{` child of the flex container will become a flex item.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.d-flex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The element behaves like a block and lays out its content using the flexbox model.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.d-inline-flex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The element behaves like an inline element and lays out its content using the flexbox model.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Example using `}<inlineCode parentName="h3">{`.d-flex`}</inlineCode></h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`Example using `}<inlineCode parentName="h3">{`.d-inline-flex`}</inlineCode></h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--inline-flex'
    }]} height="125" mdxType="StorybookEmbed" />
    <h2>{`Flex direction`}</h2>
    <p>{`Use these classes to define the orientation of the main axis (`}<inlineCode parentName="p">{`row`}</inlineCode>{` or `}<inlineCode parentName="p">{`column`}</inlineCode>{`). By default, flex items will display in a row. Use `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{` to change the direction of the main axis to vertical.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-row`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main axis runs left to right (default).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-row-reverse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main axis runs right to left.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-column`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main axis runs top to bottom.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-column-reverse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main axis runs bottom to top.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Example using `}<inlineCode parentName="h3">{`.flex-column`}</inlineCode></h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--column'
    }]} height="300" mdxType="StorybookEmbed" />
    <h3>{`Example using `}<inlineCode parentName="h3">{`.flex-column-reverse`}</inlineCode></h3>
    <p>{`This example uses the responsive variant `}<inlineCode parentName="p">{`.flex-sm-column-reverse`}</inlineCode>{` to override `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{` Learn more about `}<strong parentName="p"><a parentName="strong" {...{
          "href": "#responsive-flex-utilities"
        }}>{`responsive flexbox utilities`}</a></strong>{`. Keep in mind that it won't affect screen readers or navigating with the keyboard and it's advised to keep the markup in a logical source order.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--column-reverse'
    }]} height="300" mdxType="StorybookEmbed" />
    <h3>{`Example using `}<inlineCode parentName="h3">{`.flex-row`}</inlineCode></h3>
    <p>{`This example uses the responsive variant `}<inlineCode parentName="p">{`.flex-sm-row`}</inlineCode>{` to override `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{` Learn more about `}<strong parentName="p"><a parentName="strong" {...{
          "href": "#responsive-flex-utilities"
        }}>{`responsive flexbox utilities`}</a></strong>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--row'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`Example using `}<inlineCode parentName="h3">{`.flex-row-reverse`}</inlineCode></h3>
    <p>{`This example uses the responsive variant `}<inlineCode parentName="p">{`.flex-sm-row-reverse`}</inlineCode>{` to override `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{` Learn more about `}<strong parentName="p"><a parentName="strong" {...{
          "href": "#responsive-flex-utilities"
        }}>{`responsive flexbox utilities`}</a></strong>{`. Keep in mind that it won't affect screen readers or navigating with the keyboard and it's advised to keep the markup in a logical source order.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--row-reverse'
    }]} height="125" mdxType="StorybookEmbed" />
    <h2>{`Flex wrap`}</h2>
    <p>{`You can choose whether flex items are forced into a single line or wrapped onto multiple lines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-nowrap         { flex-wrap: nowrap; }
.flex-wrap           { flex-wrap: wrap; }
.flex-wrap-reverse   { flex-wrap: wrap-reverse; }
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-nowrap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flex items are laid out in a single line, even if they overflow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-wrap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flex items will break onto multiple lines (default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-wrap-reverse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Behaves the same as wrap but cross-start and cross-end are permuted.`}</td>
        </tr>
      </tbody>
    </table>
    <h3><inlineCode parentName="h3">{`flex-nowrap`}</inlineCode>{` example`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--nowrap'
    }]} height="220" mdxType="StorybookEmbed" />
    <h3><inlineCode parentName="h3">{`flex-wrap`}</inlineCode>{` example`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--wrap'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3><inlineCode parentName="h3">{`flex-wrap-reverse`}</inlineCode>{` example`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--wrap-reverse'
    }]} height="220" mdxType="StorybookEmbed" />
    <h2>{`Justify content`}</h2>
    <p>{`Use these classes to distribute space between and around flex items along the `}<strong parentName="p">{`main axis`}</strong>{` of the container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default behavior`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-justify-start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Justify all items to the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-justify-end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Justify all items to the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-justify-center`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Justify items to the center of the container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-justify-between`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Distribute items evenly. First item is on the start line, last item is on the end line.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-justify-around`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Distribute items evenly with equal space around them`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-justify-start`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.flex-justify-start`}</inlineCode>{` to align items to the start line. By default this will be on the left side of the container. If you are using `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{`, the start line will be at the top of the container.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--justify-start'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-justify-end`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.flex-justify-end`}</inlineCode>{` to align items to the end line. By default this will be on the right side of the container. If you are using `}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{`, the end line will be at the bottom of the container.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--justify-end'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-justify-center`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.flex-justify-center`}</inlineCode>{` to align items in the middle of the container.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--justify-center'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-justify-between`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.flex-justify-between`}</inlineCode>{` to distribute items evenly in the container. The first items will be on the start line and the last item will be on the end line.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--justify-between'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-justify-around`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.flex-justify-around`}</inlineCode>{` to distribute items evenly, with an equal amount of space around them. Visually the spaces won't look equal, since each item has the same unit of space around it. For example, the first item only has one unit of space between it and the start line, but it has two units of space between it and the next item.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--justify-around'
    }]} height="125" mdxType="StorybookEmbed" />
    <h2>{`Align items`}</h2>
    <p>{`Use these classes to align items on the `}<strong parentName="p">{`cross axis`}</strong>{`.`}</p>
    <p>{`The cross axis runs perpendicular to the main axis. By default the main axis runs horizontally, so your items will align vertically on the cross axis. If you use `}<a parentName="p" {...{
        "href": "#flex-direction"
      }}>{`flex-column`}</a>{` to make the main axis run vertically, your items will be aligned horizontally.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-items-start {
  align-items: flex-start;
}
.flex-items-end {
  align-items: flex-end;
}
.flex-items-center {
  align-items: center;
}
.flex-items-baseline {
  align-items: baseline;
}
.flex-items-stretch {
  align-items: stretch;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Behavior`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-items-start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align items to the start of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-items-end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align items to the end of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-items-center`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align items to the center of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-items-baseline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align items along their baselines`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-items-stretch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stretch items from start of cross axis to end of cross axis`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-items-start`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-items-start'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-items-end`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-items-end'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-items-center`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-items-center'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-items-baseline`}</h3>
    <p>{`With `}<inlineCode parentName="p">{`.flex-items-baseline`}</inlineCode>{`, items will be aligned along their baselines even if they have different font sizes.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-items-baseline'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-items-stretch`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-items-stretch'
    }]} height="190" mdxType="StorybookEmbed" />
    <h2>{`Align content`}</h2>
    <p>{`When the main axis wraps, this creates multiple main axis lines and adds extra space in the cross axis. Use these classes to align the lines of the main axis on the cross axis.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-content-start {
  align-content: flex-start;
}
.flex-content-end {
  align-content: flex-end;
}
.flex-content-center {
  align-content: center;
}
.flex-content-between {
  align-content: space-between;
}
.flex-content-around {
  align-content: space-around;
}
.flex-content-stretch {
  align-content: stretch;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align content to the start of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align content to the end of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-center`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align content to the center of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-between`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Distribute content evenly. First line is on the start of the cross axis, last line is on the end of the cross axis.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-around`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stretch items from the start of the cross axis to the end of the cross axis.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-content-stretch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lines stretch to occupy available space.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-content-start`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-start'
    }]} height="340" mdxType="StorybookEmbed" />
    <h3>{`flex-content-end`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-end'
    }]} height="340" mdxType="StorybookEmbed" />
    <h3>{`flex-content-center`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-center'
    }]} height="340" mdxType="StorybookEmbed" />
    <h3>{`flex-content-between`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-between'
    }]} height="340" mdxType="StorybookEmbed" />
    <h3>{`flex-content-around`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-around'
    }]} height="340" mdxType="StorybookEmbed" />
    <h3>{`flex-content-stretch`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-content-stretch'
    }]} height="340" mdxType="StorybookEmbed" />
    <h2>{`Flex`}</h2>
    <p>{`Use this class to specify the ability of a flex item to alter its dimensions to fill available space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-1 {
  flex: 1;
}
.flex-auto {
  flex: auto;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fills available space`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-auto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fills available space and auto-sizes based on the content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-grow-0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prevents growing of a flex item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-shrink-0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prevents shrinking of a flex item`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-1`}</h3>
    <p>{`Adding `}<inlineCode parentName="p">{`.flex-1`}</inlineCode>{` makes the item grow in size to take up all the space that is available.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex-1'
    }]} height="125" mdxType="StorybookEmbed" />
    <p>{`Adding `}<inlineCode parentName="p">{`.flex-1`}</inlineCode>{` to all flex items results in each item having the same size.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex-1-all'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-auto`}</h3>
    <p>{`Using `}<inlineCode parentName="p">{`.flex-auto`}</inlineCode>{` fills the available space but also takes the size of the content into account. Type in the editor below to see the effect.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex-auto'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-grow-0`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`.flex-grow-0`}</inlineCode>{` to prevent an item from growing. This can be useful when used as a responsive variant. For example to stop growing when the viewport gets wider.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex-grow-0'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-shrink-0`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`.flex-shrink-0`}</inlineCode>{` to prevent an item from shrinking. Note that for example text will not wrap and the flex items start to overflow if there is not enough space.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--flex-shrink-0'
    }]} height="160" mdxType="StorybookEmbed" />
    <h2>{`Align self`}</h2>
    <p>{`Use these classes to adjust the alignment of an individual flex item on the cross axis. This overrides any `}<inlineCode parentName="p">{`align-items`}</inlineCode>{` property applied to the flex container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-self-auto {
  align-self: auto;
}
.flex-self-start {
  align-self: flex-start;
}
.flex-self-end {
  align-self: flex-end;
}
.flex-self-center {
  align-self: center;
}
.flex-self-baseline {
  align-self: baseline;
}
.flex-self-stretch {
  align-self: stretch;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-auto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inherit alignment from parent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align to the start of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align to the end of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-center`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align to center of cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-baseline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Align baseline to the start of the cross axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-self-stretch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stretch item from start of cross axis to end of cross axis.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-self-auto`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-auto'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-self-start`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-start'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-self-end`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-end'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-self-center`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-center'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-self-baseline`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-baseline'
    }]} height="190" mdxType="StorybookEmbed" />
    <h3>{`flex-self-stretch`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--align-self-stretch'
    }]} height="190" mdxType="StorybookEmbed" />
    <h2>{`Order`}</h2>
    <p>{`Use these classes to change the order of flex items. Keep in mind that it won't affect screen readers or navigating with the keyboard and it's advised to keep the markup in a logical source order.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.flex-order-1 {
  order: 1;
}
.flex-order-2 {
  order: 2;
}
.flex-order-none {
  order: inherit;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-order-1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set order to be 1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-order-2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set order to be 2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.flex-order-none`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove order (typically used with responsive variants)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`flex-order (1+2)`}</h3>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--order'
    }]} height="125" mdxType="StorybookEmbed" />
    <h3>{`flex-order-none`}</h3>
    <p>{`Resize window to see the effect.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--order-none'
    }]} height="125" mdxType="StorybookEmbed" />
    <h2>{`Responsive flex utilities`}</h2>
    <p>{`All flexbox utilities can be adjusted per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{` using the following formulas:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`d-[breakpoint]-[property]`}</inlineCode>{` for `}<inlineCode parentName="li">{`display`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flex-[breakpoint]-[property]-[behavior]`}</inlineCode>{` for various flex properties`}</li>
    </ul>
    <p>{`Each responsive style is applied to the specified breakpoint and up.`}</p>
    <h3>{`Example classes`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* Example classes */

.d-sm-flex {
}
.d-md-inline-flex {
}

.flex-lg-row {
}
.flex-xl-column {
}

.flex-sm-wrap {
}
.flex-lg-nowrap {
}

.flex-lg-self-start {
}
`}</code></pre>
    <h3>{`Example usage`}</h3>
    <p>{`Mixing flex properties:`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--responsive'
    }]} height="190" mdxType="StorybookEmbed" />
    <h2>{`Example components`}</h2>
    <p>{`The flex utilities can be used to create a number of components. Here are some examples.`}</p>
    <h3>{`Media object`}</h3>
    <p>{`You can use flex utilities to make a simple media object, like this:`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--media-object'
    }]} height="180" mdxType="StorybookEmbed" />
    <h3>{`Responsive media object`}</h3>
    <p>{`Here is an example of a media object that is `}<strong parentName="p">{`vertically centered on large screens`}</strong>{`, but converts to a stacked column layout on small screens.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-flexbox--media-object-responsive'
    }]} height="170" mdxType="StorybookEmbed" />
    <h2>{`Flexbox bugs`}</h2>
    <p>{`This section lists flexbox bugs that affect `}<a parentName="p" {...{
        "href": "https://github.com/primer/css/blob/main/.browserslistrc"
      }}>{`browsers we currently support`}</a>{`.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Minimum content sizing of flex items not honored.`}</strong>{` Some browsers don't respect flex item size. Instead of respecting the minimum content size, items shrink below their minimum size which can create some undesirable results, such as overflowing text. The workaround is to apply `}<inlineCode parentName="li">{`flex-shrink: 0;`}</inlineCode>{` to the items using `}<inlineCode parentName="li">{`d-flex`}</inlineCode>{`. This can be applied with the `}<inlineCode parentName="li">{`flex-shrink-0`}</inlineCode>{` utility. For more information read `}<a parentName="li" {...{
          "href": "https://github.com/philipwalton/flexbugs#1-minimum-content-sizing-of-flex-items-not-honored"
        }}>{`philipwalton/flexbugs`}</a>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Some elements can't be flex containers.`}</strong>{` Specifically, `}<inlineCode parentName="li">{`<button>`}</inlineCode>{`, `}<inlineCode parentName="li">{`<fieldset>`}</inlineCode>{`, and `}<inlineCode parentName="li">{`<summary>`}</inlineCode>{` elements cannot be styled with `}<inlineCode parentName="li">{`display: flex`}</inlineCode>{` in Safari. Instead of using `}<inlineCode parentName="li">{`class="d-flex"`}</inlineCode>{` on these elements, you should nest an element within them, e.g. `}<inlineCode parentName="li">{`<summary><div class="d-flex">...</div></summary>`}</inlineCode>{`.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      